import request from '@/utils/request'

// api地址
const api = {
  login: 'passport/login',
  pwdLogin: 'passport/pwdLogin',
  kkxLogin: 'passport/kkxLogin',
  mpWxLogin: 'passport/mpWxLogin',
  captcha: 'passport/captcha',
  sendSmsCaptcha: 'passport/sendSmsCaptcha',
  userApply: 'passport/userApply',
  userApplyInfo: 'passport/userApplyInfo',
}

// 用户登录
export function login(data) {
  data.form.from = 'B'
  return request({
    url: api.login,
    method: 'post',
    data
  })
}

// 用户密码登录
export function pwdLogin(data) {
  data.form.from = 'B'
  return request({
    url: api.pwdLogin,
    method: 'post',
    data
  })
}

// 用户登录
export function kkxLogin(data) {
  data.form.from = 'B'
  return request({
    url: api.kkxLogin,
    method: 'post',
    data
  })
}

// 图形验证码
export function captcha() {
  return request({
    url: api.captcha,
    method: 'get',
    params: {}
  })
}

// 发送短信验证码
export function sendSmsCaptcha(data) {
  return request({
    url: api.sendSmsCaptcha,
    method: 'post',
    data
  })
}

// 用户申请
export function userApply(data) {
  return request({
    url: api.userApply,
    method: 'post',
    data
  })
}

// 用户申请信息
export function userApplyInfo(data) {
  return request({
    url: api.userApplyInfo,
    method: 'get',
    params: data
  })
}
